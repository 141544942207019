import '../styles/sunrise.scss';

import Account from './modules/account';
import Checkout from './modules/checkout';

import './modules/searchbar';

import './modules/about';
import { HomeMasthead } from './modules/home/masthead';


window.AccountDev = Account.Dev;
window.CheckoutDev = Checkout.Dev;

// import './modules/category-toolbar';
// import CheckoutStripe from './modules/checkout-stripe.js';
// import CheckoutSpecialRequest from './modules/checkout-special-request.js';

document.addEventListener('DOMContentLoaded', () => {
  new HomeMasthead();
});


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* ++++                                                                  ++++ */
/* ++++                             OLD CODE                             ++++ */
/* ++++                                                                  ++++ */
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

window.$ = window.jQuery;

// import '../assets/vendor/slick-carousel/slick/slick.min.js';

var sunrise = {
  checkout: {
    updating: false
  },
  account:  {},
};

/**
 * Initialize the application, folks.
 * @param  object $ jQuery
 * @return void
 */
sunrise.init = function( $ ) {
  sunrise.chosen( $ );
  sunrise.contactHash( $ );
  sunrise.expandableCategories( $ );
  sunrise.mobileNav( $ );
  // sunrise.productOrdering( $ );
  sunrise.checkout.bind( $ );
  sunrise.account.bind( $ );
}


/**
 * Handle mobile nav
 */
sunrise.mobileNav = function( $ ) {
  $( '#mobile-nav-btn' ).on( 'click', function() {
    $( this ).toggleClass( 'is-active' );
    $( '#nav-search-wrap' ).toggleClass( 'is-active' );
    return false;
  } );
}

sunrise.expandableCategories = function( $ ) {
  var $Wrapper = jQuery( '.category-expandable' );
  jQuery( '.has-children > a', $Wrapper ).on( 'click', sunrise.expandableCategories.expand );
}

/**
 * Subcategory accordion list
 */
sunrise.expandableCategories.expand = function() {

  var $Item = jQuery( this ).parent();

  jQuery( '>ul', $Item ).slideToggle();
  $Item.toggleClass( 'active' );

  return false;
}

// /**
//  * Product list sort order form
//  */
// sunrise.productOrdering = function( $ ) {

//  var $Form = $( '.category-ordering' );

//  $Form.on( 'change', function() {
//    $Form.submit();
//  })
// }

/**
 * Chosen bindings
 */
sunrise.chosen = function( $ ) {
  var options = {
    disable_search: true,
    width:          '48%'
  };

  if( $('select.orderby').length ) {
    options.width = 100;
  }

  $('.chosen select').chosen( options );
}

/**
 * Autoselect the proper contact subject if hash says so
 */
sunrise.contactHash = function( $ ) {

  var valids = [
    '#support',
    '#help'
  ];

  if( window.location.hash && $.inArray( window.location.hash, valids ) > -1 ) {

    var hash = window.location.hash.replace( '#', '' );

    if( hash == 'help' ) {
      $( '.contact-form select option:nth-child(2)' ).attr( 'selected', 'selected' );
    }

    if( hash == 'support' ) {
      $( '.contact-form select option:nth-child(3)' ).attr( 'selected', 'selected' );
    }

    $( '.chosen select' ).trigger( 'chosen:updated' );

  }
}



/**
 * Checkout process bindings
 */
sunrise.checkout.bind = function( $ ) {

  this.$specialRequests     = $( 'textarea#order_special_request' );
  this.$saveAddressToggle   = $( '.save-checkout-address-toggle input' );
  this.$createAccountToggle = $( '#registerAccount' );
  this.$checkoutForm        = $( 'form.woocommerce-checkout');

  // this.$specialRequests.on(     'keyup', this.toggleSpecialRequestNotice );
  // this.$checkoutForm.on(        'keyup keypress', this.disableEnterToSubmit );
  // this.$saveAddressToggle.on(   'change', this.toggleNickname );
  // this.$createAccountToggle.on( 'change', this.toggleAccount );


  // $( document.body ).on( 'updated_checkout', function() {
    // document.body.dispatchEvent(CheckoutUpdatedEvent);

    // $( 'input[name="wc-stripe-payment-token"]' ).trigger( 'change' );
  // });

}

/**
 * Toggle the special request warning notice on checkout.
 */
// sunrise.checkout.toggleSpecialRequestNotice = function( e ) {
//  if( $( this ).val().length ) {
//    $( '#order_special_request_field' ).find( '.description span' ).slideDown();
//  } else {
//    $( '#order_special_request_field' ).find( '.description span' ).slideUp();
//  }
// }

/**
 * Disable the ability to submit the checkout form using the [return] key
 */
// sunrise.checkout.disableEnterToSubmit = function( e ) {

//   var keycode = e.keyCode || e.which;

//   if( keycode === 13 ) {
//     e.preventDefault();
//     return false;
//   }
// }

/**
 * Update the checkout totals
 */
// sunrise.checkout.checkoutUpdate = function( e ) {

//  if( sunrise.checkout.updating ) {
//    return;
//  }

//  sunrise.checkout.numUpdate++;
//  sunrise.checkout.updating = true;

//  $( document.body ).trigger( 'update_checkout' );

//  sunrise.checkout.updating = false;
// }

/**
 * Toggle the display of the nickname fields for saving an address on the
 * checkout page.
 */
sunrise.checkout.toggleNickname = function( e ) {

  var $toggle    = $( this ),
    $fieldWrap = $toggle.parent().parent(),
    $nickname  = $fieldWrap.next( '.checkout-nickname-field' ),
    checked    = $toggle.is( ':checked' );

  if( checked ) {
    $nickname.slideDown();
  } else {
    $nickname.slideUp( function() {
      $nickname.val('');
    });
  }
}

/**
 * Toggle the display of the "Create an Account?" fields on the checkout page.
 */
// sunrise.checkout.toggleAccount = function( e ) {
//  if( $( this ).is( ':checked' ) ) {
//    $( '.account-registration' ).slideDown();
//  } else {
//    $( '.account-registration' ).slideUp(function() {
//      $( this ).find( 'input' ).val( '' );
//    });
//  }
// }


/**
 * This is a fix to toggle the Stripe payment method form as sometimes the
 * toggle functionality that comes with the plugin does not work properly.
 */
// sunrise.checkout.toggleCCForm = function() {

//  var selected = $( 'input[name="wc-stripe-payment-token"]:checked' ).val();

//  console.log( 'sunrise.checkout.toggleCCForm' + "\r\n", selected );


//  if( selected == 'new' ) {
//    $( '#wc-stripe-cc-form' ).slideDown();
//  } else {
//    $( '#wc-stripe-cc-form' ).slideUp();
//  }
// }

/**
 * DEVELOPMENT ONLY
 * Automatically fill the checkout fields with some data and whatnot.
 */
sunrise.checkout.fillForms = function() {

  var $ = jQuery;

  $( '#billing_phone, #shipping_phone' ).val( '281-330-8004' );
  $( '#billing_company, #shipping_company' ).val( 'Flywheel Co.' );
  $( '#billing_attn_name, #shipping_attn_name' ).val( 'C. Everson' );

  $( '#billing_country, #shipping_country' ).val( 'US' ).trigger( 'change' ); // FIX THIS

  $( '#billing_address_1, #shipping_address_1' ).val( '617 1/2 W 19th St' );
  $( '#billing_city, #shipping_city' ).val( 'Houston' );
  $( '#billing_state, #shipping_state' ).val( 'CA' ).trigger( 'change' );
  $( '#billing_postcode, #shipping_postcode' ).val( '77008' );

  $( '#billing_email' ).val( 'chris@flywheel.co' );
}


sunrise.account.bind = function( $ ) {
  this.$deleteBtn = $( '.delete-address' );
  this.$deleteBtn.bind( 'click', sunrise.account.deleteAddress );
}

sunrise.account.deleteAddress = function() {

  var $     = jQuery,
    $item = $( this ).closest( 'li' ),
    key   = $item.attr( 'data-key' );

  var confirmation = confirm( 'Are you sure you want to delete this address? This action can not be undone.' );

  if( ! confirmation ) {
    return false;
  }

}


sunrise.fillCheckoutFields = function() {

  $( '#billing_email' ).val( 'chris+13@flywheel.co' );
  $( '#billing_phone' ).val( '123-456-7890' );
  $( '#billing_company' ).val( 'Flywheel Co.' );
  $( '#billing_attn_name' ).val( 'Not Logged In' );
  $( '#billing_address_1' ).val( '617 1/2 19th Street' );
  $( '#billing_city' ).val( 'Houston' );
  $( '#billing_state' ).val( 'TX' );
  $( '#billing_postcode' ).val( '77008' );
  $( '#billing_country' ).val( 'US' ).trigger( 'change' );

  $( '#shipping_phone' ).val( '123-456-7890' );
  $( '#shipping_company' ).val( 'Flywheel Co.' );
  $( '#shipping_attn_name' ).val( 'Not Logged In' );
  $( '#shipping_address_1' ).val( '617 1/2 19th Street' );
  $( '#shipping_city' ).val( 'Houston' );
  $( '#shipping_state' ).val( 'TX' );
  $( '#shipping_postcode' ).val( '77008' );
  $( '#shipping_country' ).val( 'US' ).trigger( 'change' );
}

jQuery( sunrise.init );
