import Coupon from './coupon';
import Dev from './dev';
import ShippingState from './shipping-state';
import TaxExemption from './tax-exemption';
import SpecialRequest from './special-request';
import PaymentMethod from './payment-method';
import SavedAddress from './saved-address';
import SaveAddress from './save-address';
import Conditions from './conditions';

let modules = {
  Coupon,
  ShippingState,
  TaxExemption,
  SpecialRequest,
  PaymentMethod,
  SavedAddress,
  SaveAddress,
  Conditions,
};

if(process.env.NODE_ENV == 'development') {
  modules.Dev = new Dev;
}

export default modules;
