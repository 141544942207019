const $ = jQuery;

class Coupon {
  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {
    this.$form = document.querySelector('.checkout_coupon');
    this.$formInput = document.querySelector('.checkout_coupon input');
    this.$formButton = document.querySelector('.checkout_coupon button');

    this.$alias = document.querySelector('.checkout_coupon-alias');
    this.$aliasInput = document.querySelector('.checkout_coupon-alias input');
    this.$aliasButton = document.querySelector('.checkout_coupon-alias button');

    if(!this.$alias) {
      return;
    }

    this.$aliasInput.addEventListener('input', e => this.update());
    this.$aliasButton.addEventListener('click', e => this.submit());
  }

  update() {
    this.$formInput.value = this.$aliasInput.value;
  }

  submit() {
    this.update();
    $(this.$form).submit();

    $('html, body').animate({
      scrollTop: $('.woocommerce').offset().top
    }, 600);
  }
}

export default new Coupon;
