const $ = jQuery;

class SaveAddress {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {
    this.$toggleInputs = $('.save_address input');
    this.$nicknameInputs = $('.save_nickname input');

    if(!this.$toggleInputs.length) {
      return;
    }

    this.reset();
    this.$toggleInputs.on('input', (e) => this.toggleNicknameField(e));
  }

  toggleNicknameField(e) {
    const $field = $(e.currentTarget).closest('div').parent().find('.save_nickname');

    $field.toggleClass('is-active');
  }

  reset() {
    this.$toggleInputs.prop('checked', false);
    this.$nicknameInputs.val('');
  }
}

export default new SaveAddress;
