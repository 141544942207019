const $ = jQuery;

class PaymentMethod {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {

    this.$inputs = $('input[name="payment_method"]');

    if(!this.$inputs.length) {
      return;
    }

    $(document.body).on('updated_checkout', () => {
      $('input[name="payment_method"]').on('input', () => {
        this.update();
      });
    })
  }


  update() {

    let buttonLabel = 'Place Order';

    if($('input[name="payment_method"]:checked').val() == 'sunrise_gateway_exception') {
      buttonLabel = 'Submit My Order And Contact Me';
    }

    $('#place_order').val(buttonLabel);
    $('#place_order').data('value', buttonLabel);
    $('#place_order').html(buttonLabel);
    console.log('UPDATE PAYMENT METHOD', $('input[name="payment_method"]:checked').val());
    // $(document.body).trigger('update_checkout');
  }
}

export default new PaymentMethod;
