import Dev from './dev';
import TaxExemption from './tax-exemption';

let modules = {
  TaxExemption,
};

if(process.env.NODE_ENV == 'development') {
  modules.Dev = new Dev;
}

export default modules;
