const $ = jQuery;

class CheckoutTaxExemption {
    constructor() {
        window.addEventListener('DOMContentLoaded', (e) => {
            this.fields = {
                toggle: document.getElementById('shipping_has_tax_id_field'),
                id: document.getElementById('shipping_tax_id_field'),
            };

            this.inputs = {
                toggle: document.getElementById('shipping_has_tax_id'),
                id: document.getElementById('shipping_tax_id'),
            };

            this.bootBillingEmail();
            this.bootExemptionRequest();

            this.updated();
            $(document.body).on('updated_checkout', () => this.updated());

            // this.modal = document.querySelector('.tax-exemption-modal');
            // this.modalCancel = document.querySelector('.tax-exemption-modal-cancel');
            // this.modalConfirm = document.querySelector('.tax-exemption-modal-confirm');

            // this.nagTrigger = document.getElementById('tax-exemption-nag-link');

            // this.bootNag();
            // this.bootModal();

            // this.updated();
        });
    }

    /**
     * Handle billing email address action bindings.
     */
    bootBillingEmail() {
        const input = document.getElementById('billing_email');

        if (!input) return;

        input.addEventListener('change', (e) => this.update());
    }

    bootExemptionRequest() {
        this.inputs.toggle.addEventListener('input', (e) => {
            if (e.currentTarget.checked) {
                this.fields.id.classList.add('is-active');
            } else {
                this.resetExemptionRequest();
            }

            this.update();
        });

        this.inputs.id.addEventListener('blur', () => this.update());
    }

    // bootNag() {
    //     if (!this.nagTrigger) {
    //         return;
    //     }

    //     this.nagTrigger.addEventListener('click', (e) => {
    //         e.preventDefault();
    //         this.openModal();
    //     });
    // }

    // bootModal() {
    //     this.modalCancel.addEventListener('click', () => this.cancel());
    //     this.modalConfirm.addEventListener('click', () => this.confirm());
    // }

    // openModal() {
    //     this.modal.style = 'display:flex';
    // }

    // closeModal() {
    //     this.modal.style = 'display:none';
    //     this.toggleIdField();
    // }

    // cancel() {
    //     if (this.toggleInput) {
    //         this.toggleInput.checked = false;
    //         this.update();
    //     }

    //     this.closeModal();
    // }

    // confirm() {
    //     if (this.toggleInput) {
    //         this.toggleInput.checked = true;
    //         this.update();
    //     }

    //     this.closeModal();
    // }

    /**
     * Toggle the notice that displays below the billing email field.
     */
    toggleBillingEmailNotice() {
        const field = document.getElementById('billing_email_field');

        if (this.hasExemption()) {
            field.classList.add('billing-email-exempt');
        } else {
            field.classList.remove('billing-email-exempt');
        }
    }

    /**
     * Toggle the exemption request fields.
     */
    toggleExemptionRequest() {
        const state = document.getElementById('shipping_state').value;
        const taxable = ['CA', 'TN', 'NC'];

        if (state && taxable.includes(state) && !this.hasExemption()) {
            Object.values(this.fields).forEach((field) => field.classList.add('is-visible'));

            if (this.inputs.toggle.checked) {
                this.fields.id.classList.add('is-active');
            }
        } else {
            Object.values(this.fields).forEach((field) => field.classList.remove('is-visible'));
            this.resetExemptionRequest();
        }
    }

    /**
     * Whether an AvaTax exemption is already in place.
     * @returns {Boolean}
     */
    hasExemption() {
        return !!document.querySelector('.tax-total.tax-total-exempt');
    }

    /**
     * Handle checkout updated events.
     */
    updated() {
        this.toggleBillingEmailNotice();
        this.toggleExemptionRequest();
    }

    /**
     * Trigger a checkout update.
     */
    update() {
        $(document.body).trigger('update_checkout');
    }

    /**
     * Reset the exemption request values.
     */
    resetExemptionRequest() {
        this.inputs.toggle.checked = false;
        $(this.inputs.id).val(null);
    }
}

export default new CheckoutTaxExemption();
