class Conditions {

    constructor() {
        window.addEventListener('DOMContentLoaded', this.boot);
    }

    boot() {
        this.$button = document.querySelector('.checkout-conditions-confirmm');

        if(this.$button) {
            console.log('button exists');

            jQuery('.checkout-conditions-confirmm').on('click', (ev) => {
                console.log('onclick');
                ev.preventDefault();
                return false;
            })

            jQuery('.checkout-conditions-confirmm').click((event) => {
                event.preventDefault();
                console.log('click');
                this.confirm()
            });
        }
    }

    confirm() {
        document.querySelector('.checkout-actions').classList.remove('checkout-actions-unconfirmed');
    }

}

export default new Conditions();
