const $ = jQuery;

class Searchbar {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {
    this.$toggle = document.querySelector('.search-toggle');
    this.$input = document.querySelector('.search-field');

    this.$toggle.addEventListener('click', e => this.toggle(e));
  }

  toggle(e) {
    e.preventDefault();

    const $wrapper = document.getElementById('primary');

    $wrapper.classList.toggle('search');

    if($wrapper.classList.contains('search')) {
      $(this.$input).focus();
    } else {
      $(this.$input).focusout();
    }
  }

}

export default new Searchbar;
