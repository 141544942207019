const $ = jQuery;

class SavedAddress {

  constructor() {
    $(() => this.boot());
  }

  boot() {
    this.$selects = $('.existing_address select');

    if(!this.$selects.length) {
      return;
    }

    this.$selects.selectWoo();

    this.$selects.on('select2:select', (e) => this.update(e));
  }


  update(e) {
    const type = e.currentTarget.id.includes('shipping') ? 'shipping' : 'billing';

    this.populate(e.currentTarget.value, type);
  }

  populate(addressId, type) {
    if(!parseInt(addressId)) {
      return false;
    }

    let $fields = $('.woocommerce-' + type + '-fields input, .woocommerce-' + type + '-fields select');
    let data = existing_addresses[addressId];
    let elems = [];

    $fields.each((index, el) => {
      elems.push(el);
    });

    elems.forEach(el => {
      el.value = data[el.id] ? data[el.id] : '';

      if(el.classList.contains('country_select') || el.classList.contains('state_select')) {
        $(el).trigger('change');
      }
    });

    $(document.body).trigger('update_checkout');
  }

}

export default new SavedAddress;
