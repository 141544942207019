const $ = jQuery;

class ShippingState {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {
    const $field = $('#shipping_state');

    if(!$field.length) {
      return;
    }

    $field.on('change', () => {
      $(document.body).trigger('update_checkout');
    })
  }
}

export default new ShippingState;
