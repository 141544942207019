import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'


export class HomeMasthead {
    constructor() {
        this.$el = document.querySelector('.home-masthead');
        this.glide = null;

        if(this.$el) {
            this.boot();
        }
    }

    boot() {
        console.log('booting homepage masthead');

        this.glide = new Glide(this.$el, {
            autoplay: 8000,
        }).mount({ Controls, Breakpoints, Autoplay });
    }


}
