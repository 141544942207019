const $ = jQuery;

class SpecialRequest {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {

    this.$input = document.getElementById('order_special_request');

    if(!this.$input) {
      return;
    }

    this.$input.addEventListener('blur', () => this.update());
  }


  update() {
    $(document.body).trigger('update_checkout');
  }
}

export default new SpecialRequest;
