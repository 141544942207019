const $ = jQuery;

class AccountTaxExemption {

  constructor() {
    window.addEventListener('DOMContentLoaded', e => this.boot());
  }

  boot() {
    if(!document.querySelector('.woocommerce-MyAccount-content')) {
      return;
    }

    this.$toggleInput = document.getElementById('shipping_has_tax_id');
    this.$toggleField = document.getElementById('shipping_has_tax_id_field');

    this.$idInput = document.getElementById('shipping_tax_id');
    this.$idField = document.getElementById('shipping_tax_id_field');

    this.$countryInput = document.getElementById('shipping_country');
    this.$stateInput = document.getElementById('shipping_state');

    if(!this.$toggleInput) {
      return;
    }

    this.updated();

    $(this.$stateInput).on('change', () => this.updated());

    this.$toggleInput.addEventListener('input', () => this.toggleIdField());
    this.$idInput.addEventListener('blur', () => this.update());
  }

  toggleVisibility() {
    if(this.$countryInput.value != 'US') {
      this.$toggleField.classList.remove('is-visible');
      this.$idField.classList.remove('is-visible');
      this.reset()
      return;
    }

    const state = this.$stateInput.value;
    const taxableStates = ['CA', 'TN'];

    if(state && taxableStates.includes(state)) {
      this.$toggleField.classList.add('is-visible');
      this.$idField.classList.add('is-visible');
    } else {
      this.$toggleField.classList.remove('is-visible');
      this.$idField.classList.remove('is-visible');
      this.reset()
    }
  }

  toggleIdField() {
    if(this.$toggleInput.checked) {
      this.$idField.classList.add('is-active');
    } else {
      this.$idField.classList.remove('is-active');
    }
  }

  updated() {
    this.toggleVisibility();
    this.toggleIdField();
  }

  reset() {
    this.$toggleInput.checked = false;
    this.$idInput.value = '';
  }
}

export default new AccountTaxExemption;
