/**
 * About page scripts
 *
 * ported from main.js [2020-08]
 */

const toggleBio = e => {
  e.preventDefault();

  const $li = e.currentTarget.parentNode;

  if($li.classList.contains('active')) {
    return;
  }

  $li.parentNode.querySelectorAll('li').forEach($el => {
    $el.classList.toggle('active');
  });

  $li.closest('.team-member').querySelectorAll('.bio-item').forEach($el => {
    $el.classList.toggle('active');
  })
}

export default (() => {
  document.querySelectorAll('.bio-nav a').forEach(el => {
    el.addEventListener('click', e => toggleBio(e));
  });
})();

